import React from 'react';

import Layout from '../components/blocks/Layout';
import SEO from '../components/blocks/SEO';
import Security from '../components/slides/security/Security';

const Page = () => {
  return (
    <Layout>
      <SEO
        title="Security | Deadline Funnel"
        description="Security"
      />
      <Security />
    </Layout>
  )
};

export default Page;